import { uuid } from 'vue-uuid'

export const state = () => ({
  // Changes for Menu Actions
  changesActions: [],
  // Changes for Grid Editing
  changesGrid: [],
  // Just for display elements in the "save" table
  changesTable: [],
  tab: 'solicitud',
})

//commit mutations
export const mutations = {
  INIT_TAB(state) {
    state.tab = 'solicitud'
  },
  SET_TAB(state, val) {
    state.tab = val
  },
  resetQueueChanges(state) {
    state.changesActions = []
    state.changesGrid = []
    state.changesTable = []
  },
  // Changes for Menu Actions
  updateChangesActions(state, data) {
    let exist = false
    const changes = {
      newIteration: data.newIteration,
      tipo: data.tipo,
      iteracion: data.iteracion,
      uuids: data.uuids,
      action: data.action,
      value: data.value,
    }
    if (state.changesActions.length > 0) {
      state.changesActions.forEach((element) => {
        if (
          element.tipo === changes.tipo &&
          element.action === changes.action &&
          element.value === changes.value
        ) {
          exist = true
          changes.uuids.forEach((id) => {
            if (!element.uuids.includes(id)) element.uuids.push(id)
          })
        }
      })
    }
    if (!exist) state.changesActions.push(changes)
  },
  // Changes for Grid Editing
  updateChangesGrid(state, data) {
    let exist = false
    const changes = {
      uuid: data.uuid,
      tipo: data.tipo,
      iteracion: data.iteracion,
      row: {
        field: data.row.field,
        value: data.row.value,
      },
    }
    if (state.changesGrid.length > 0) {
      state.changesGrid.forEach((element) => {
        if (element.tipo === changes.tipo && element.uuid === changes.uuid) {
          exist = true
          let fieldExist = false
          element.row.forEach((element) => {
            if (element.field === changes.row.field) {
              element.value = changes.row.value
              fieldExist = true
            }
          })
          if (!fieldExist) element.row.push(data.row)
        }
      })
    }
    if (!exist) {
      changes.row = [
        {
          field: data.row.field,
          value: data.row.value,
        },
      ]
      state.changesGrid.push(changes)
    }
  },
  // Just for display elements in the "save" table
  updateChangesTable(state, data) {
    for (let i = 0; i < data.length; i++) {
      let exist = false
      if (state.changesTable.length > 0) {
        state.changesTable.forEach((element) => {
          if (
            element.tipo === data[i].tipo &&
            element.row === data[i].row &&
            element.action === data[i].action &&
            element.field === data[i].field
          ) {
            let idx = state.changesTable.indexOf(element)
            state.changesTable[idx] = data[i]
            exist = true
          }
        })
      }
      if (!exist) state.changesTable.push(data[i])
    }
  },
}

//dispatch actions
export const actions = {
  async saveChanges({ rootState, state, commit }, empresa) {
    rootState.loading = true
    let error = false
    let info_error = ''

    await this.dispatch('custom_variables/check')

    console.log('saveChanges -> state.changesGrid', state.changesGrid)
    // Procesamos los cambios
    for (let index = 0; index < state.changesGrid.length; index++) {
      const row = state.changesGrid[index]
      try {
        await this.$axios.patch(`iteraciones/${row.iteracion}/sites`, row)
      } catch (e) {
        console.error('[ERROR] [Store expedientes iteraciones-sites]', e)
        info_error = e.message
        error = true
        break
      }
    }

    // procesamos todas las actions.
    if (state.changesActions.length > 0 && !error) {
      await this.$axios
        .patch(`${empresa}/actions`, state.changesActions)
        .then(function (response) {
          if (response && response !== undefined) commit('resetQueueChanges')
          return true
        })
        .catch(function (error) {
          console.error('[ERROR] [Store expedientes actions]', error)
          return false
        })
        .then(function () {
          rootState.loading = false
        })
    } else {
      if (!error) commit('resetQueueChanges')
      rootState.loading = false
      return { error: error, info_error: info_error }
    }

    // TODO: No esta cambiado el error a "true" si se encuentra un error -------------------------------->
    // if (!error) commit('resetQueueChanges')
    // rootState.loading = false;
    // return { error: error, info_error: info_error }
  },

  async list({ rootState }, body) {
    rootState.loading = true
    
    let res = null
    let params = {}
    params.page = body.page || 1
    params.perPage = body.perPage || 100
    params.sortBy = body.sortBy || 'nombre'
    params.sortDesc = body.sortDesc || false
    let url = `/expedientes?page=${params.page}&itemsPerPage=${params.perPage}&order[${
      params.sortBy || 'nombre'
    }]=${params.sortDesc ? 'desc' : 'asc'}`
    if (body.search) {
      params.nombre = body.search.toLowerCase()

      url = url + `&search=${params.nombre}`
    }
    res = await this.$axios.get(url)
    rootState.loading = false
    if (res) return res
  },

  async listBySubalcance({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`expedientes?subalcance.uuid=${uuid}`)
    rootState.loading = false
    if (res) return res.data['hydra:member']
  },

  async expediente({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`expedientes/${uuid}`)

    // TODO: <listas-validacion> Establecimiento de datos para obtener las listas de validación */
    // todo: lanzar evento de cambio de alcance.
    rootState.listas_validacion.ref_uuid = res.data.uuid
    if (res.data.alcance && res.data.alcance.uuid)
      rootState.listas_validacion.alcance = res.data.alcance.uuid
    /* </listas-validacion> */

    rootState.loading = false
    if (res) return res.data
  },

  async activar({ rootState }, expediente_uuid) {
    rootState.loading = true
    const res = await this.$axios.post(`expedientes/${expediente_uuid}/activar`, {
      expediente: expediente_uuid,
    })
    rootState.loading = false
    if (res) return res.data
  },

  async desactivar({ rootState }, expediente_uuid) {
    rootState.loading = true
    const res = await this.$axios.post(`expedientes/${expediente_uuid}/desactivar`, {
      expediente: expediente_uuid,
    })
    rootState.loading = false
    if (res) return res.data
  },

  async iteracion({ rootState }, uuid_iteracion) {
    rootState.loading = true
    const res = await this.$axios.get(
      `iteraciones/${uuid_iteracion}`
    )
    rootState.loading = false
    if (res) return res.data
  },

  async createAuditoria({ rootState }, params) {
    rootState.loading = true
    const auditoria = params.auditoria
    const uuid_expediente = params.expediente_uuid
    const uuid_iteracion = params.iteracion_uuid
    const data = JSON.parse(JSON.stringify(auditoria))
    data.iteracion = '/api/iteraciones/'+uuid_iteracion
    data.uuid = uuid.v4()
    console.log('createAuditoria -> data', data)
    await this.$axios.post(`auditorias`, data)
    rootState.loading = false
  },

  //+-------------------------------------------------
  // updateFechasIteracion()
  // -----
  // Created on Thu Jul 21 2022
  //+-------------------------------------------------
  async updateFechasIteracion({ rootState }, params) {
    rootState.loading = true

    console.log('updateFechasIteracion -> body', params)
    const res = await this.$axios.patch(`iteraciones/${params.uuid}`, params)

    rootState.loading = false
    if (res) return res.data
  },

  async updateCodigo({ rootState }, expediente) {
    rootState.loading = true
    const body = {
      expediente: expediente.uuid,
      codigo: expediente.codigo,
    }
    console.log('updateCodigo -> body', body)
    const res = await this.$axios.patch(`expedientes/${expediente.uuid}`, body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
    rootState.loading = false
    // if (res) return res.data
  },
  getByCliente({ rootState }, cliente_uuid) {
    rootState.loading = true
    const res = this.$axios.get(`expedientes?user.uuid=${cliente_uuid}`)
    rootState.loading = false
    if (res && res.data) return res.data['hydra:member']
  },

  async update({ rootState }, element) {
    console.info('update element', element)
    const xhr = await this.$axios.patch(`expedientes/${element.uuid}`, element,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    })
    if (xhr) return xhr.data
  },

}
